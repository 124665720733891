import React from "react";
import { Link } from "react-router-dom";
import TradingDollr from "../../img/trading-dollr.png";
export default function AboutDollar() {
  return (
    <>
      <div className="about-dollar pt-5 pb-5" id="about-token">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
              <div className="about-dollarL" data-aos="fade-right">
                <h2 className="title-text-two tx-left mb-4">
                  About <span className="text-yellow">Dollaro</span>{" "}
                </h2>
                <p className="text-white p-text">
                  Dollaro is a blockchain-powered platform that can help you
                  unlock the full potential of your digital assets.
                  Additionally, it redefines the staking and re-staking
                  experience.
                </p>
                <p className="text-white p-text">
                  It is designed to provide users with efficient liquidity
                  distribution and even allows them to maximize returns while
                  maintaining complete control over their investments.
                </p>
                <p className="text-white p-text">
                  We believe in complete transparency, that is, we follow a
                  transparent process. Dollaro operates with no hidden fees or
                  surprises, which ensures a seamless and hassle-free experience
                  for all users. Our platform is built to help users with easy
                  access to staking opportunities.
                </p>
                <p className="text-white p-text">
                  So, experience the future of digital asset management with
                  Dollaro!
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-12 col-xs-12"
              data-aos="fade-left"
            >
              <img src={TradingDollr} className="img-ani" />
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5">
              <div className="howto-join">
                <h2 className="title-text-two tx-center mb-4">
                  How To <span className="text-yellow">Join</span>{" "}
                </h2>
                <p className="text-white p-text w100 text-center">
                  Dollaro supports both metamask & trust wallet dapp & browser
                  extension. You can register on Dollaro using any wallet.
                </p>
              </div>
            </div>

            <div class="row mt-5 process_line">
              <i class="bi bi-circle-fill c_fill1"></i>
              <i class="bi bi-circle-fill c_fill2"></i>
              <div class="col-md-4 ">
                <div class="process_box">
                  <div class="process_box_img">
                    <img
                      decoding="async"
                      src="../../assest/img/p1.jpg"
                      alt=" Payment Gateway"
                    />
                    <span>01</span>
                  </div>
                  <p class="">
                    Maintain USDT And BNB In Your Metamask Or Trust Wallet For
                    Activation And Gas Fee.
                  </p>
                </div>
                <i class="bi bi-caret-right-fill"></i>
              </div>
              <div class="col-md-4 ">
                <div class="process_box">
                  <div class="process_box_img">
                    <img
                      decoding="async"
                      src="../../assest/img/p2.jpg"
                      alt=" Payment Gateway"
                    />
                    <span>02</span>
                  </div>
                  <p class="">
                    https:/ Open Referral Link In The DAPP BROWSER{" "}
                  </p>
                </div>
                <i class="bi bi-caret-right-fill"></i>
              </div>
              <div class="col-md-4 ">
                <div class="process_box">
                  <div class="process_box_img">
                    <img
                      decoding="async"
                      src="../../assest/img/p3.jpg"
                      alt=" Payment Gateway"
                    />
                    <span>03</span>
                  </div>
                  <p class="">
                    Invest With Just 100$ Usdt And Start Your Journey With
                    Dollaro.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-12 text-center m-auto">
              <Link class="btn btn-primary mt-4 " to="/login">Get Started</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
