import React from "react";
import BeinCrypto from "../../img/marquee/beincrypto.svg";
import CoinDesk from "../../img/marquee/coindesk.png";
import CoinMaket from "../../img/marquee/coinmaket.svg";
import CoinTelegraph from "../../img/marquee/cointelegraph.svg";
import CryptoDaily from "../../img/marquee/cryptodaily.png";
import CryptoNews from "../../img/marquee/cryptonews.png";
import Binance from "../../img/marquee/binance.png";
import CoinGabber from "../../img/marquee/coingabbar.png";
import Htx from "../../img/marquee/htx.png";
import Medium from "../../img/marquee/medium.png";
import Gateio from "../../img/marquee/gateio.png";
import Potential from "../../img/marquee/potential.png";
import Steemit from "../../img/marquee/steemit.png";
import Topicolist from "../../img/marquee/topicolist.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function AsSeen() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10,
    cssEase: "linear",
    centerMode: true,
    arrows: false,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="as-seen-in pb-5">
        <div className="container">
          <div
            className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-5"
            data-aos="fade-up"
          >
            <h2 className="title-text-two">
              AS <span className="text-yellow"> SEEN </span> IN{" "}
            </h2>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-4 mt-5">
            <div className="marquee-box" data-aos="fade-up">
              <Slider {...settings}>
                {/* <div style={{ margin: "0 10px", display: "inline-block" }}>
                  <a href="/">
                    <img src={BeinCrypto} className="marquee-image desk" />
                  </a>
                </div>
                <div style={{ margin: "0 10px", display: "inline-block" }}>
                  <a href="/">
                    <img src={CoinDesk} className="marquee-image desk" />
                  </a>
                </div>
                <div style={{ margin: "0 10px", display: "inline-block" }}>
                  <a href="/">
                    <img src={CoinMaket} className="marquee-image desk" />
                  </a>
                </div>
                <div style={{ margin: "0 10px", display: "inline-block" }}>
                  <a href="/">
                    <img src={CoinTelegraph} className="marquee-image desk" />
                  </a>
                </div>
                <div style={{ margin: "0 10px", display: "inline-block" }}>
                  <a href="/">
                    {" "}
                    <img src={CryptoDaily} className="marquee-image desk" />
                  </a>
                </div>
                <div style={{ margin: "0 10px", display: "inline-block" }}>
                  <a href="/">
                    <img src={CryptoNews} className="marquee-image desk" />
                  </a>
                </div> */}
                <div style={{ margin: "0 10px", display: "inline-block" }}>
                  <a href="https://www.binance.com/en-IN/square/post/17015032084378?_ul=aHR0cHM6Ly9hcHAuYmluYW5jZS5jb20vdW5pLXFyL2NhcnQvMTcwMTUwMzIwODQzNzg_dWNvPWl5dEFHbFdOWmdkc3ZEc0tXd2pGUHcmdXM9Y29weWxpbmsmbD1lbi1JTiZyPTU3MDc4Mzg4NCZ1Yz13ZWJfc3F1YXJlX3NoYXJlX2xpbms&ref=570783884&utm_campaign=web_square_share_link&utm_content=iytAGlWNZgdsvDsKWwjFPw&utm_source=copylink" target="_blank" rel="nofollow">
                    <img src={Binance} className="marquee-image desk" />
                  </a>
                </div>
                <div style={{ margin: "0 10px", display: "inline-block" }}>
                  <a
                    href="https://www.coingabbar.com/en/crypto-blogs-details/crypto-presale-top-5-crypto-presales-to-buy-today" target="_blank" rel="nofollow">
                    <img src={CoinGabber} className="marquee-image desk" />
                  </a>
                </div>

                <div style={{ margin: "0 10px", display: "inline-block" }}>
                  <a href="https://topicolist.com/dollaro" target="_blank" rel="nofollow">
                    <img src={Topicolist} className="marquee-image desk" />
                  </a>
                </div>
                <div style={{ margin: "0 10px", display: "inline-block" }}>
                  <a href="https://medium.com/@coing2733/dollaro-for-crypto-investors-best-staking-platform-in-2024-8b3bbee7e449" target="_blank" rel="nofollow">
                    <img src={Medium} className="marquee-image desk" />
                  </a>
                </div>
                {/* <div style={{ margin: "0 10px", display: "inline-block" }}>
                  <a >
                    <img src={Gateio} className="marquee-image desk" />
                  </a>
                </div> */}
                <div style={{ margin: "0 10px", display: "inline-block" }}>
                  <a href="https://steemit.com/cryptostaking/@coin.gabbar/dollaro-for-crypto-investors-best-staking-platform-in-2024" target="_blank" rel="nofollow">
                    <img src={Steemit} className="marquee-image desk" />
                  </a>
                </div>
                <div style={{ margin: "0 10px", display: "inline-block" }}>
                  <a href="https://crypto-potential.com/post/dollaro-for-crypto-investors%3A-best-staking-platform-in-2024-1" target="_blank" rel="nofollow">
                    <img src={Potential} className="marquee-image desk" />
                  </a>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
