import React from 'react';

const RoadMap = () => {
  return (
    <>

<div className='roadMap pt-5 pb-5' id='roadmap'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
              <h2 className='title-text-two mb-md-4' data-aos="fade-up">Dollaro <span className='text-yellow'>Road Map</span> </h2>
            </div>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
              <div className='road-map'>

              <div class="timeline mt-md-5">
                  <div class="container-box left">
                     <div class="date">PHASE 1</div>
                     <i class="icon fa fa-home"></i>
                     <div class="content">
                        <h3>1 OCT - Developing</h3>
                        <ul className="mt-3">
                           <li>Plan Discussion</li>
                           <li>Functionality</li>
                           <li>Smart Contract</li>
                           <li>Bep20 Token Process</li>
                           <li>Security Building</li>
                        </ul>
                     </div>
                  </div>
                  <div class="container-box right">
                     <div class="date">PHASE 2</div>
                     <i class="icon fa fa-gift"></i>
                     <div class="content">
                        <h3>14 NOV- Presale</h3>
                        <ul className="mt-3">
                           <li>Staking</li>
                           <li>Airdrop</li>
                           <li>Referral</li>
                           <li>Deposit and withdrawals</li>
                           <li>Stage 1 Starts</li>
                        </ul>
                     </div>
                  </div>
                  <div class="container-box left">
                     <div class="date">PHASE 3</div>
                     <i class="icon fa fa-user"></i>
                     <div class="content">
                        <h3>29 NOV- Stage 2 Starts</h3>
                        <ul className="mt-3">
                           <li>Increase in price</li>
                           <li>1usdt to 1.25usdt</li>
                           <li>Work on crypto loans module</li>
                           <li>Marketing</li>
                        </ul>
                     </div>
                  </div>

                  <div class="container-box right">
                     <div class="date">PHASE 4</div>
                     <i class="icon fa fa-gift"></i>
                     <div class="content">
                        <h3>14 DEC - Stage 3 Starts</h3>
                        <ul className="mt-3">
                           <li>Increase in price</li>
                           <li>1.25usdt to 1.50usdt</li>
                           <li>Token Burning process</li>
                        </ul>
                     </div>
                  </div>

                  <div class="container-box left">
                     <div class="date">PHASE 6</div>
                     <i class="icon fa fa-user"></i>
                     <div class="content">
                        <h3>29 DEC- Stage4 Starts</h3>
                        <ul className="mt-3">
                           <li>Increase in Price</li>
                           <li>1.50usdt to 2 usdt</li>
                           <li>Live events and expo</li>
                           <li>Multi earning system</li>
                        </ul>
                     </div>
                  </div>

                  <div class="container-box right">
                     <div class="date">PHASE 4</div>
                     <i class="icon fa fa-gift"></i>
                     <div class="content">
                        <h3>14 JAN 2025- End of ico</h3>
                        <ul className="mt-3">
                           <li>Exchange listing at 5usdt</li>
                           <li>Distribution of crypto loans</li>
                           <li>50+ currency accepted</li>
                           <li>Work on volume2 module</li>
                           <li>Lucky spin for lucky users</li>
                           <li>Restaking programme</li>
                           <li>Discounts and much more upcoming</li>
                        </ul>
                     </div>
                  </div>
                  
                  
              </div>












              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default RoadMap;
